@import url("https://fonts.googleapis.com/css?family=Roboto");

/* Global Styles */
:root {
  --primary-color: #55add8;
  --dark-color: #333333;
  --light-color: #f4f4f4;
  --danger-color: #dc3545;
  --success-color: #28a745;
}

/* * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
} */

body {
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  background-color: #fff;
}

.mysize {
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
  line-height: 1.6;
  background-color: #fff;
}
.inputfield {
  height: 30px;
}

.close-x {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 7px solid #f56b00;
  background: linear-gradient(
      45deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 43%,
      #fff 45%,
      #fff 55%,
      rgba(0, 0, 0, 0) 57%,
      rgba(0, 0, 0, 0) 100%
    ),
    linear-gradient(135deg, #f56b00 0%, #f56b00 43%, #fff 45%, #fff 55%, #f56b00 57%, #f56b00 100%);
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

a:hover {
  color: #666;
}

/* ul {
  list-style: none;
} */

img {
  width: 100%;
}

/* Utilities */
.container {
  max-width: 2100px;
  margin: auto;
  overflow: hidden;
  padding: 0 2rem;
}

/* Text Styles*/
.x-large {
  font-size: 4rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.large {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.lead {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.text-center {
  text-align: center;
}

.text-primary {
  color: var(--primary-color);
}

.text-dark {
  color: var(--dark-color);
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

/* Cards */
.card {
  padding: 1rem;
  border: #ccc 1px dotted;
  margin: 0.7rem 0;
}

/* List */
.list {
  margin: 0.5rem 0;
}

.list li {
  padding-bottom: 0.3rem;
}

/* Padding */
.p {
  padding: 0.5rem;
}
.p-1 {
  padding: 1rem;
}
.pr-1 {
  padding-right: 1rem;
}
.p-2 {
  padding: 2rem;
}
.p-3 {
  padding: 3rem;
}
.py {
  padding: 0.5rem 0;
}
.py-1 {
  padding: 1rem 0;
}
.py-2 {
  padding: 2rem 0;
}
.py-3 {
  padding: 3rem 0;
}

/* Margin */
.m {
  margin: 0.5rem;
}

.mtt-1 {
  margin-top: -2rem;
}
.mtt-3 {
  margin-top: -1.5rem;
}
.mtt-2 {
  margin-top: -1rem;
}
.m-1 {
  margin: 1rem;
}
.m-2 {
  margin: 2rem;
}
.m-3 {
  margin: 3rem;
}
.my {
  margin: 0.5rem 0;
}
.my-1 {
  margin: 1rem 0;
}
.my-2 {
  margin: 2rem 0;
}
.my-3 {
  margin: 3rem 0;
}

/* Grid */
/* .grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
}

.grid-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
}

.grid-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
}

.grid-2-3 {
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-gap: 2rem;
} */

/* .btn {
  display: inline-block;
  background: var(--light-color);
  color: #333;
  padding: 0.4rem 1.3rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  margin-right: 0.5rem;
  transition: opacity 0.2s ease-in;
  outline: none;
} */

.btn-link {
  background: none;
  padding: 0;
  margin: 0;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-sm {
  font-size: 0.8rem;
  padding: 0.3rem 1rem;
  margin-right: 0.2rem;
}

.badge {
  font-size: 0.8rem;
  padding: 0.2rem 0.7rem;
  text-align: center;
  margin: 0.3rem;
  background: var(--light-color);
  color: #333;
  border-radius: 5px;
}

.alert {
  padding: 0.7rem;
  margin: 1rem 0;
  opacity: 0.9;
  background: var(--light-color);
  color: #333;
  font-family: Arial, Helvetica, sans-serif;
}

.btn-primary,
.bg-primary,
.badge-primary,
.alert-primary {
  background: var(--primary-color);
  color: #fff;
}

.btn-light,
.bg-light,
.badge-light,
.alert-light {
  background: var(--light-color);
  color: #333;
}

.btn-dark,
.bg-dark,
.badge-dark,
.alert-dark {
  background: var(--dark-color);
  color: #fff;
}

.btn-danger,
.bg-danger,
.badge-danger,
.alert-danger {
  background: var(--danger-color);
  color: #fff;
}

.btn-success,
.bg-success,
.badge-success,
.alert-success {
  background: var(--success-color);
  color: #fff;
}

.btn-white,
.bg-white,
.badge-white,
.alert-white {
  background: #fff;
  color: #333;
  border: #ccc solid 1px;
}

.btn:hover {
  opacity: 0.8;
}

.bg-light,
.badge-light {
  border: #ccc solid 1px;
}

.round-img {
  border-radius: 50%;
}

/* Forms */
/* input {
  margin: 1.2rem 0;
} */
/* 
.form-text {
  display: block;
  margin-top: 0.3rem;
  color: #888;
} */

.mynumber {
  display: block;
  width: 100%;
  padding: 0.4rem;
  font-size: 1.2rem;
  border: 1px solid #ccc;
}

/* input[type='text'],
input[type='email'],
input[type='password'],
input[type='date'],
select,
textarea {
  display: block;
  width: 100%;
  padding: 0.4rem;
  font-size: 1.2rem;
  border: 1px solid #ccc;
}

input[type='submit'],
button {
  font: inherit;
} */

/* .form-group {
  margin: 1.2rem 0;
} */

/* .form-group input {
  margin: 0.2rem 0;
} */

/* .form-container {
  max-width: 500px;
  margin: 2rem auto;
  overflow: hidden;
  padding: 0 2rem;
}

.form-container h1,
h2,
h3 {
  text-align: center;
} */

/* table,
table td {
  padding: 1rem;
  text-align: left;
} */

.tbheader {
  background-color: #033699;
  color: #ffffff;
}

/* table th {
  background: var(--light-color);
} */

/* Navbar */
/* .navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 2rem;
  z-index: 1;
  width: 100%;
  border-bottom: solid 1px var(--primary-color);
  opacity: 0.9;
  margin-bottom: 1rem;
} */

/* .navbar {
  display: flex;
}

.navbar a {
  color: #fff;
  padding: 0.45rem;
  margin: 0 0.25rem;
}

.navbar a:hover {
  color: var(--dark-color);
}

.navbar .welcome span {
  margin-right: 4rem;
} */

/* Animation (Add After) */
.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease-in;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: opacity 1000ms ease-in;
}

.categorybackground {
  background-color: #aaebffa6;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.labeltextcolor {
  font-weight: bolder;
}

.categorybackgroundtwo {
  background-color: #d4f6ff00;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.bidformbackground {
  background-color: #d4f6ff48;
}

.myreadonly {
  background-color: #053947fb;
}

/* .logo {
  height: 100px;
  width: 100px;
} */

/* Mobile Styles */
@media (max-width: 700px) {
  .hide-sm {
    display: none;
  }

  .grid-2,
  .grid-3,
  .grid-4 {
    grid-template-columns: 1fr;
  }

  /* Text Styles */
  .x-large {
    font-size: 3rem;
  }

  .large {
    font-size: 2rem;
  }

  .lead {
    font-size: 1rem;
  }

  /* Navbar */
  /* .navbar {
    display: block;
    text-align: center;
  }

  .navbar h1 {
    margin-bottom: 0.6rem;
  }

  .navbar ul {
    text-align: center;
    justify-content: center;
  } */
}

.btn-xs {
  font-size: 0.8rem;
  padding: 0.1rem 0.3rem;
  // margin-right: 0.2rem;
}

.mynav {
  background-color: #55add8;
}

.ant-table-thead > tr > th {
  color: white;
  background: #55add8 !important;
}

.ant-table-tbody > tr > th {
  color: white;
  background: #3071b9 !important;
}

.logo {
  float: left;
  width: 140px;
  height: 31px;
  margin: 16px 24px 16px 0;
  /* background: rgba(127, 227, 235, 0.3); */
  border-radius: 4px;
}

.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
}

.ant-layout-header {
  background-color: #55add8 !important;
}

.ant-menu-horizontal {
  line-height: 50px !important;
}

.myfooter {
  position: fixed;
  bottom: 0;
}

.myCommentBackground {
  background-color: #e9e9e9;
  margin-top: 8px;
  border-radius: 3px;
}

.bglight {
  background-color: #f8f6f6fb;
}

.myheader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.myheaderitem {
  flex-grow: 1;
  border-radius: 10px;
  // background-color: #55add8 !important;
}
.myheaderitemtwo {
  flex-grow: 2;
  text-align: center;
}

.mrt {
  margin-right: 2em;
}

.mytag {
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 2px;
  padding-top: 2px;
  font-size: large;
}

.hiderow {
  // transition: all ease-in-out 0.5s;
  opacity: 0;
}
.hiderow:hover {
  // transition: all ease-in-out 0.5s;
  opacity: 1;
}

.flexcontainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
}

.flexcontainertwo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
}

.flexcontainerspaceevenly {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.myitem {
  padding-left: 4px;
  width: 400px;
}

.pl5 {
  padding-left: 10px;
}

.mycustompopover {
  .ant-popover-inner {
    background-color: rgb(231, 242, 248);
    // background-color: rgb(255, 241, 253);
  }
  .ant-popover-title {
    color: rgb(78, 22, 72);
    font-size: 18pt;
  }
  width: 40vw;
}

.popoverh5 {
  color: rgb(104, 31, 96);
}

.atag {
  color: rgb(30, 82, 255);
}

.boldLabel {
  // font-size: 13pt;
  font-weight: bolder;
}

.redbg:read-only {
  background-color: #f4caca;
}

.greenbg:read-only {
  background-color: #bbdbc2;
}

.darkgreenbg:read-only {
  background-color: #80b58b;
}

.rowdivider {
  margin-bottom: 40pt;
}

.pointer {
  cursor: pointer;
}
.myFont {
  font-family: Arial, Helvetica, sans-serif;
}

.buttonLink {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}

// .ant-menu{
//   // background: transparent;
//   background-color: #55add8 !important;

//   }
// .ant-menu-item{
//   // background: transparent;
//  color: #55add8 !important;

//   }

// .mylink{
//  color: #ffffff !important;
// }
